// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }
declare var DELIMITER: any;
declare var EOF: any;
declare var LIMIT: any;
declare var COMMA: any;
declare var RESERVED_SELECT: any;
declare var ASTERISK: any;
declare var RESERVED_COMMAND: any;
declare var RESERVED_SET_OPERATION: any;
declare var ARRAY_IDENTIFIER: any;
declare var ARRAY_KEYWORD: any;
declare var RESERVED_FUNCTION_NAME: any;
declare var BETWEEN: any;
declare var AND: any;
declare var OPERATOR: any;
declare var IDENTIFIER: any;
declare var QUOTED_IDENTIFIER: any;
declare var VARIABLE: any;
declare var NAMED_PARAMETER: any;
declare var QUOTED_PARAMETER: any;
declare var NUMBERED_PARAMETER: any;
declare var POSITIONAL_PARAMETER: any;
declare var NUMBER: any;
declare var STRING: any;
declare var RESERVED_KEYWORD: any;
declare var RESERVED_PHRASE: any;
declare var RESERVED_DEPENDENT_CLAUSE: any;
declare var RESERVED_JOIN: any;
declare var CASE: any;
declare var END: any;
declare var OR: any;
declare var XOR: any;
declare var LINE_COMMENT: any;
declare var BLOCK_COMMENT: any;

import LexerAdapter from 'src/parser/LexerAdapter';
import { NodeType } from 'src/parser/ast';
import { Token, TokenType } from 'src/lexer/token';

// The lexer here is only to provide the has() method,
// that's used inside the generated grammar definition.
// A proper lexer gets passed to Nearley Parser constructor.
const lexer = new LexerAdapter(chunk => []);

// Used for unwrapping grammar rules like:
//
//   rule -> ( foo | bar | baz )
//
// which otherwise produce single element nested inside two arrays
const unwrap = <T>([[el]]: T[][]): T => el;

const toKeywordNode = (token: Token) => ({
  type: NodeType.keyword,
  tokenType: token.type,
  text: token.text,
  raw: token.raw,
});

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: lexer,
  ParserRules: [
    {"name": "main$ebnf$1", "symbols": []},
    {"name": "main$ebnf$1", "symbols": ["main$ebnf$1", "statement"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "main", "symbols": ["main$ebnf$1"], "postprocess": 
        ([statements]) => {
          const last = statements[statements.length - 1];
          if (last && !last.hasSemicolon) {
            // we have fully parsed the whole file
            // discard the last statement when it's empty
            return last.children.length > 0 ? statements : statements.slice(0, -1);
          } else {
            // parsing still in progress, do nothing
            return statements;
          }
        }
        },
    {"name": "statement$subexpression$1", "symbols": [(lexer.has("DELIMITER") ? {type: "DELIMITER"} : DELIMITER)]},
    {"name": "statement$subexpression$1", "symbols": [(lexer.has("EOF") ? {type: "EOF"} : EOF)]},
    {"name": "statement", "symbols": ["expressions_or_clauses", "statement$subexpression$1"], "postprocess": 
        ([children, [delimiter]]) => ({
          type: NodeType.statement,
          children,
          hasSemicolon: delimiter.type === TokenType.DELIMITER,
        })
        },
    {"name": "expressions_or_clauses$ebnf$1", "symbols": []},
    {"name": "expressions_or_clauses$ebnf$1", "symbols": ["expressions_or_clauses$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "expressions_or_clauses$ebnf$2", "symbols": []},
    {"name": "expressions_or_clauses$ebnf$2", "symbols": ["expressions_or_clauses$ebnf$2", "clause"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "expressions_or_clauses", "symbols": ["expressions_or_clauses$ebnf$1", "expressions_or_clauses$ebnf$2"], "postprocess": 
        ([expressions, clauses]) => [...expressions, ...clauses]
        },
    {"name": "clause$subexpression$1", "symbols": ["limit_clause"]},
    {"name": "clause$subexpression$1", "symbols": ["select_clause"]},
    {"name": "clause$subexpression$1", "symbols": ["other_clause"]},
    {"name": "clause$subexpression$1", "symbols": ["set_operation"]},
    {"name": "clause", "symbols": ["clause$subexpression$1"], "postprocess": unwrap},
    {"name": "limit_clause$ebnf$1", "symbols": ["commaless_expression"]},
    {"name": "limit_clause$ebnf$1", "symbols": ["limit_clause$ebnf$1", "commaless_expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "limit_clause$ebnf$2$subexpression$1$ebnf$1", "symbols": ["expression"]},
    {"name": "limit_clause$ebnf$2$subexpression$1$ebnf$1", "symbols": ["limit_clause$ebnf$2$subexpression$1$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "limit_clause$ebnf$2$subexpression$1", "symbols": [(lexer.has("COMMA") ? {type: "COMMA"} : COMMA), "limit_clause$ebnf$2$subexpression$1$ebnf$1"]},
    {"name": "limit_clause$ebnf$2", "symbols": ["limit_clause$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "limit_clause$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "limit_clause", "symbols": [(lexer.has("LIMIT") ? {type: "LIMIT"} : LIMIT), "limit_clause$ebnf$1", "limit_clause$ebnf$2"], "postprocess": 
        ([limitToken, exp1, optional]) => {
          if (optional) {
            const [comma, exp2] = optional;
            return {
              type: NodeType.limit_clause,
              name: toKeywordNode(limitToken),
              offset: exp1,
              count: exp2,
            };
          } else {
            return {
              type: NodeType.limit_clause,
              name: toKeywordNode(limitToken),
              count: exp1,
            };
          }
        }
        },
    {"name": "select_clause$subexpression$1$ebnf$1", "symbols": []},
    {"name": "select_clause$subexpression$1$ebnf$1", "symbols": ["select_clause$subexpression$1$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "select_clause$subexpression$1", "symbols": ["all_columns_asterisk", "select_clause$subexpression$1$ebnf$1"]},
    {"name": "select_clause$subexpression$1$ebnf$2", "symbols": []},
    {"name": "select_clause$subexpression$1$ebnf$2", "symbols": ["select_clause$subexpression$1$ebnf$2", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "select_clause$subexpression$1", "symbols": ["asteriskless_expression", "select_clause$subexpression$1$ebnf$2"]},
    {"name": "select_clause", "symbols": [(lexer.has("RESERVED_SELECT") ? {type: "RESERVED_SELECT"} : RESERVED_SELECT), "select_clause$subexpression$1"], "postprocess": 
        ([nameToken, [exp, expressions]]) => ({
          type: NodeType.clause,
          name: toKeywordNode(nameToken),
          children: [exp, ...expressions],
        })
        },
    {"name": "all_columns_asterisk", "symbols": [(lexer.has("ASTERISK") ? {type: "ASTERISK"} : ASTERISK)], "postprocess": 
        () => ({ type: NodeType.all_columns_asterisk })
        },
    {"name": "other_clause$ebnf$1", "symbols": []},
    {"name": "other_clause$ebnf$1", "symbols": ["other_clause$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "other_clause", "symbols": [(lexer.has("RESERVED_COMMAND") ? {type: "RESERVED_COMMAND"} : RESERVED_COMMAND), "other_clause$ebnf$1"], "postprocess": 
        ([nameToken, children]) => ({
          type: NodeType.clause,
          name: toKeywordNode(nameToken),
          children,
        })
        },
    {"name": "set_operation$ebnf$1", "symbols": []},
    {"name": "set_operation$ebnf$1", "symbols": ["set_operation$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "set_operation", "symbols": [(lexer.has("RESERVED_SET_OPERATION") ? {type: "RESERVED_SET_OPERATION"} : RESERVED_SET_OPERATION), "set_operation$ebnf$1"], "postprocess": 
        ([nameToken, children]) => ({
          type: NodeType.set_operation,
          name: toKeywordNode(nameToken),
          children,
        })
        },
    {"name": "expression$subexpression$1", "symbols": ["simple_expression"]},
    {"name": "expression$subexpression$1", "symbols": ["asterisk"]},
    {"name": "expression$subexpression$1", "symbols": ["comma"]},
    {"name": "expression", "symbols": ["expression$subexpression$1"], "postprocess": unwrap},
    {"name": "asteriskless_expression$subexpression$1", "symbols": ["simple_expression"]},
    {"name": "asteriskless_expression$subexpression$1", "symbols": ["comma"]},
    {"name": "asteriskless_expression", "symbols": ["asteriskless_expression$subexpression$1"], "postprocess": unwrap},
    {"name": "commaless_expression$subexpression$1", "symbols": ["simple_expression"]},
    {"name": "commaless_expression$subexpression$1", "symbols": ["asterisk"]},
    {"name": "commaless_expression", "symbols": ["commaless_expression$subexpression$1"], "postprocess": unwrap},
    {"name": "simple_expression$subexpression$1", "symbols": ["array_subscript"]},
    {"name": "simple_expression$subexpression$1", "symbols": ["function_call"]},
    {"name": "simple_expression$subexpression$1", "symbols": ["parenthesis"]},
    {"name": "simple_expression$subexpression$1", "symbols": ["curly_braces"]},
    {"name": "simple_expression$subexpression$1", "symbols": ["square_brackets"]},
    {"name": "simple_expression$subexpression$1", "symbols": ["between_predicate"]},
    {"name": "simple_expression$subexpression$1", "symbols": ["expression_token"]},
    {"name": "simple_expression", "symbols": ["simple_expression$subexpression$1"], "postprocess": unwrap},
    {"name": "array_subscript", "symbols": [(lexer.has("ARRAY_IDENTIFIER") ? {type: "ARRAY_IDENTIFIER"} : ARRAY_IDENTIFIER), "square_brackets"], "postprocess": 
        ([arrayToken, brackets]) => ({
          type: NodeType.array_subscript,
          array: { type: NodeType.identifier, text: arrayToken.text },
          parenthesis: brackets,
        })
        },
    {"name": "array_subscript", "symbols": [(lexer.has("ARRAY_KEYWORD") ? {type: "ARRAY_KEYWORD"} : ARRAY_KEYWORD), "square_brackets"], "postprocess": 
        ([arrayToken, brackets]) => ({
          type: NodeType.array_subscript,
          array: toKeywordNode(arrayToken),
          parenthesis: brackets,
        })
        },
    {"name": "function_call", "symbols": [(lexer.has("RESERVED_FUNCTION_NAME") ? {type: "RESERVED_FUNCTION_NAME"} : RESERVED_FUNCTION_NAME), "parenthesis"], "postprocess": 
        ([nameToken, parens]) => ({
          type: NodeType.function_call,
          name: toKeywordNode(nameToken),
          parenthesis: parens,
        })
        },
    {"name": "parenthesis", "symbols": [{"literal":"("}, "expressions_or_clauses", {"literal":")"}], "postprocess": 
        ([open, children, close]) => ({
          type: NodeType.parenthesis,
          children: children,
          openParen: "(",
          closeParen: ")",
        })
        },
    {"name": "curly_braces$ebnf$1", "symbols": []},
    {"name": "curly_braces$ebnf$1", "symbols": ["curly_braces$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "curly_braces", "symbols": [{"literal":"{"}, "curly_braces$ebnf$1", {"literal":"}"}], "postprocess": 
        ([open, children, close]) => ({
          type: NodeType.parenthesis,
          children: children,
          openParen: "{",
          closeParen: "}",
        })
        },
    {"name": "square_brackets$ebnf$1", "symbols": []},
    {"name": "square_brackets$ebnf$1", "symbols": ["square_brackets$ebnf$1", "expression"], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "square_brackets", "symbols": [{"literal":"["}, "square_brackets$ebnf$1", {"literal":"]"}], "postprocess": 
        ([open, children, close]) => ({
          type: NodeType.parenthesis,
          children: children,
          openParen: "[",
          closeParen: "]",
        })
        },
    {"name": "between_predicate", "symbols": [(lexer.has("BETWEEN") ? {type: "BETWEEN"} : BETWEEN), "commaless_expression", (lexer.has("AND") ? {type: "AND"} : AND), "commaless_expression"], "postprocess": 
        ([betweenToken, expr1, andToken, expr2]) => ({
          type: NodeType.between_predicate,
          between: toKeywordNode(betweenToken),
          expr1: [expr1],
          and: toKeywordNode(andToken),
          expr2: [expr2],
        })
        },
    {"name": "comma$subexpression$1", "symbols": [(lexer.has("COMMA") ? {type: "COMMA"} : COMMA)]},
    {"name": "comma", "symbols": ["comma$subexpression$1"], "postprocess": ([[token]]) => ({ type: NodeType.comma })},
    {"name": "asterisk$subexpression$1", "symbols": [(lexer.has("ASTERISK") ? {type: "ASTERISK"} : ASTERISK)]},
    {"name": "asterisk", "symbols": ["asterisk$subexpression$1"], "postprocess": ([[token]]) => ({ type: NodeType.operator, text: token.text })},
    {"name": "expression_token$subexpression$1", "symbols": ["operator"]},
    {"name": "expression_token$subexpression$1", "symbols": ["identifier"]},
    {"name": "expression_token$subexpression$1", "symbols": ["parameter"]},
    {"name": "expression_token$subexpression$1", "symbols": ["literal"]},
    {"name": "expression_token$subexpression$1", "symbols": ["keyword"]},
    {"name": "expression_token$subexpression$1", "symbols": ["comment"]},
    {"name": "expression_token", "symbols": ["expression_token$subexpression$1"], "postprocess": unwrap},
    {"name": "operator$subexpression$1", "symbols": [(lexer.has("OPERATOR") ? {type: "OPERATOR"} : OPERATOR)]},
    {"name": "operator", "symbols": ["operator$subexpression$1"], "postprocess": ([[token]]) => ({ type: NodeType.operator, text: token.text })},
    {"name": "identifier$subexpression$1", "symbols": [(lexer.has("IDENTIFIER") ? {type: "IDENTIFIER"} : IDENTIFIER)]},
    {"name": "identifier$subexpression$1", "symbols": [(lexer.has("QUOTED_IDENTIFIER") ? {type: "QUOTED_IDENTIFIER"} : QUOTED_IDENTIFIER)]},
    {"name": "identifier$subexpression$1", "symbols": [(lexer.has("VARIABLE") ? {type: "VARIABLE"} : VARIABLE)]},
    {"name": "identifier", "symbols": ["identifier$subexpression$1"], "postprocess": ([[token]]) => ({ type: NodeType.identifier, text: token.text })},
    {"name": "parameter$subexpression$1", "symbols": [(lexer.has("NAMED_PARAMETER") ? {type: "NAMED_PARAMETER"} : NAMED_PARAMETER)]},
    {"name": "parameter$subexpression$1", "symbols": [(lexer.has("QUOTED_PARAMETER") ? {type: "QUOTED_PARAMETER"} : QUOTED_PARAMETER)]},
    {"name": "parameter$subexpression$1", "symbols": [(lexer.has("NUMBERED_PARAMETER") ? {type: "NUMBERED_PARAMETER"} : NUMBERED_PARAMETER)]},
    {"name": "parameter$subexpression$1", "symbols": [(lexer.has("POSITIONAL_PARAMETER") ? {type: "POSITIONAL_PARAMETER"} : POSITIONAL_PARAMETER)]},
    {"name": "parameter", "symbols": ["parameter$subexpression$1"], "postprocess": ([[token]]) => ({ type: NodeType.parameter, key: token.key, text: token.text })},
    {"name": "literal$subexpression$1", "symbols": [(lexer.has("NUMBER") ? {type: "NUMBER"} : NUMBER)]},
    {"name": "literal$subexpression$1", "symbols": [(lexer.has("STRING") ? {type: "STRING"} : STRING)]},
    {"name": "literal", "symbols": ["literal$subexpression$1"], "postprocess": ([[token]]) => ({ type: NodeType.literal, text: token.text })},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("RESERVED_KEYWORD") ? {type: "RESERVED_KEYWORD"} : RESERVED_KEYWORD)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("RESERVED_PHRASE") ? {type: "RESERVED_PHRASE"} : RESERVED_PHRASE)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("RESERVED_DEPENDENT_CLAUSE") ? {type: "RESERVED_DEPENDENT_CLAUSE"} : RESERVED_DEPENDENT_CLAUSE)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("RESERVED_JOIN") ? {type: "RESERVED_JOIN"} : RESERVED_JOIN)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("CASE") ? {type: "CASE"} : CASE)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("END") ? {type: "END"} : END)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("AND") ? {type: "AND"} : AND)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("OR") ? {type: "OR"} : OR)]},
    {"name": "keyword$subexpression$1", "symbols": [(lexer.has("XOR") ? {type: "XOR"} : XOR)]},
    {"name": "keyword", "symbols": ["keyword$subexpression$1"], "postprocess": 
        ([[token]]) => toKeywordNode(token)
        },
    {"name": "comment", "symbols": [(lexer.has("LINE_COMMENT") ? {type: "LINE_COMMENT"} : LINE_COMMENT)], "postprocess": 
        ([token]) => ({
          type: NodeType.line_comment,
          text: token.text,
          precedingWhitespace: token.precedingWhitespace,
        })
        },
    {"name": "comment", "symbols": [(lexer.has("BLOCK_COMMENT") ? {type: "BLOCK_COMMENT"} : BLOCK_COMMENT)], "postprocess": 
        ([token]) => ({ type: NodeType.block_comment, text: token.text })
        }
  ],
  ParserStart: "main",
};

export default grammar;
