import { flatKeywordList } from '../../utils';

export const functions = flatKeywordList({
  // http://spark.apache.org/docs/latest/sql-ref-functions.html
  //
  // http://spark.apache.org/docs/latest/sql-ref-functions-builtin.html#aggregate-functions
  aggregate: [
    // 'ANY',
    'APPROX_COUNT_DISTINCT',
    'APPROX_PERCENTILE',
    'AVG',
    'BIT_AND',
    'BIT_OR',
    'BIT_XOR',
    'BOOL_AND',
    'BOOL_OR',
    'COLLECT_LIST',
    'COLLECT_SET',
    'CORR',
    'COUNT',
    'COUNT',
    'COUNT',
    'COUNT_IF',
    'COUNT_MIN_SKETCH',
    'COVAR_POP',
    'COVAR_SAMP',
    'EVERY',
    'FIRST',
    'FIRST_VALUE',
    'GROUPING',
    'GROUPING_ID',
    'KURTOSIS',
    'LAST',
    'LAST_VALUE',
    'MAX',
    'MAX_BY',
    'MEAN',
    'MIN',
    'MIN_BY',
    'PERCENTILE',
    'PERCENTILE',
    'PERCENTILE_APPROX',
    'SKEWNESS',
    // 'SOME',
    'STD',
    'STDDEV',
    'STDDEV_POP',
    'STDDEV_SAMP',
    'SUM',
    'VAR_POP',
    'VAR_SAMP',
    'VARIANCE',
  ],
  // http://spark.apache.org/docs/latest/sql-ref-functions-builtin.html#window-functions
  window: [
    'CUME_DIST',
    'DENSE_RANK',
    'LAG',
    'LEAD',
    'NTH_VALUE',
    'NTILE',
    'PERCENT_RANK',
    'RANK',
    'ROW_NUMBER',
  ],
  // http://spark.apache.org/docs/latest/sql-ref-functions-builtin.html#array-functions
  array: [
    'ARRAY',
    'ARRAY_CONTAINS',
    'ARRAY_DISTINCT',
    'ARRAY_EXCEPT',
    'ARRAY_INTERSECT',
    'ARRAY_JOIN',
    'ARRAY_MAX',
    'ARRAY_MIN',
    'ARRAY_POSITION',
    'ARRAY_REMOVE',
    'ARRAY_REPEAT',
    'ARRAY_UNION',
    'ARRAYS_OVERLAP',
    'ARRAYS_ZIP',
    'FLATTEN',
    'SEQUENCE',
    'SHUFFLE',
    'SLICE',
    'SORT_ARRAY',
  ],
  // http://spark.apache.org/docs/latest/sql-ref-functions-builtin.html#map-functions
  map: [
    'ELEMENT_AT',
    'ELEMENT_AT',
    'MAP',
    'MAP_CONCAT',
    'MAP_ENTRIES',
    'MAP_FROM_ARRAYS',
    'MAP_FROM_ENTRIES',
    'MAP_KEYS',
    'MAP_VALUES',
    'STR_TO_MAP',
  ],
  // http://spark.apache.org/docs/latest/sql-ref-functions-builtin.html#date-and-timestamp-functions
  datetime: [
    'ADD_MONTHS',
    'CURRENT_DATE',
    'CURRENT_DATE',
    'CURRENT_TIMESTAMP',
    'CURRENT_TIMESTAMP',
    'CURRENT_TIMEZONE',
    'DATE_ADD',
    'DATE_FORMAT',
    'DATE_FROM_UNIX_DATE',
    'DATE_PART',
    'DATE_SUB',
    'DATE_TRUNC',
    'DATEDIFF',
    'DAY',
    'DAYOFMONTH',
    'DAYOFWEEK',
    'DAYOFYEAR',
    'EXTRACT',
    'FROM_UNIXTIME',
    'FROM_UTC_TIMESTAMP',
    'HOUR',
    'LAST_DAY',
    'MAKE_DATE',
    'MAKE_DT_INTERVAL',
    'MAKE_INTERVAL',
    'MAKE_TIMESTAMP',
    'MAKE_YM_INTERVAL',
    'MINUTE',
    'MONTH',
    'MONTHS_BETWEEN',
    'NEXT_DAY',
    'NOW',
    'QUARTER',
    'SECOND',
    'SESSION_WINDOW',
    'TIMESTAMP_MICROS',
    'TIMESTAMP_MILLIS',
    'TIMESTAMP_SECONDS',
    'TO_DATE',
    'TO_TIMESTAMP',
    'TO_UNIX_TIMESTAMP',
    'TO_UTC_TIMESTAMP',
    'TRUNC',
    'UNIX_DATE',
    'UNIX_MICROS',
    'UNIX_MILLIS',
    'UNIX_SECONDS',
    'UNIX_TIMESTAMP',
    'WEEKDAY',
    'WEEKOFYEAR',
    'WINDOW',
    'YEAR',
  ],
  // http://spark.apache.org/docs/latest/sql-ref-functions-builtin.html#json-functions
  json: [
    'FROM_JSON',
    'GET_JSON_OBJECT',
    'JSON_ARRAY_LENGTH',
    'JSON_OBJECT_KEYS',
    'JSON_TUPLE',
    'SCHEMA_OF_JSON',
    'TO_JSON',
  ],
  // http://spark.apache.org/docs/latest/api/sql/index.html
  misc: [
    'ABS',
    'ACOS',
    'ACOSH',
    'AGGREGATE',
    'ARRAY_SORT',
    'ASCII',
    'ASIN',
    'ASINH',
    'ASSERT_TRUE',
    'ATAN',
    'ATAN2',
    'ATANH',
    'BASE64',
    'BIGINT',
    'BIN',
    'BINARY',
    'BIT_COUNT',
    'BIT_GET',
    'BIT_LENGTH',
    'BOOLEAN',
    'BROUND',
    'BTRIM',
    'CARDINALITY',
    'CBRT',
    'CEIL',
    'CEILING',
    'CHAR',
    'CHAR_LENGTH',
    'CHARACTER_LENGTH',
    'CHR',
    'CONCAT',
    'CONCAT_WS',
    'CONV',
    'COS',
    'COSH',
    'COT',
    'CRC32',
    'CURRENT_CATALOG',
    'CURRENT_DATABASE',
    'CURRENT_USER',
    'DATE',
    'DECIMAL',
    'DEGREES',
    'DOUBLE',
    // 'E',
    'ELT',
    'EXP',
    'EXPM1',
    'FACTORIAL',
    'FIND_IN_SET',
    'FLOAT',
    'FLOOR',
    'FORALL',
    'FORMAT_NUMBER',
    'FORMAT_STRING',
    'FROM_CSV',
    'GETBIT',
    'HASH',
    'HEX',
    'HYPOT',
    'INITCAP',
    'INLINE',
    'INLINE_OUTER',
    'INPUT_FILE_BLOCK_LENGTH',
    'INPUT_FILE_BLOCK_START',
    'INPUT_FILE_NAME',
    'INSTR',
    'INT',
    'ISNAN',
    'ISNOTNULL',
    'ISNULL',
    'JAVA_METHOD',
    'LCASE',
    'LEFT',
    'LENGTH',
    'LEVENSHTEIN',
    'LN',
    'LOCATE',
    'LOG',
    'LOG10',
    'LOG1P',
    'LOG2',
    'LOWER',
    'LPAD',
    'LTRIM',
    'MAP_FILTER',
    'MAP_ZIP_WITH',
    'MD5',
    'MOD',
    'MONOTONICALLY_INCREASING_ID',
    'NAMED_STRUCT',
    'NANVL',
    'NEGATIVE',
    'NVL',
    'NVL2',
    'OCTET_LENGTH',
    'OVERLAY',
    'PARSE_URL',
    'PI',
    'PMOD',
    'POSEXPLODE',
    'POSEXPLODE_OUTER',
    'POSITION',
    'POSITIVE',
    'POW',
    'POWER',
    'PRINTF',
    'RADIANS',
    'RAISE_ERROR',
    'RAND',
    'RANDN',
    'RANDOM',
    'REFLECT',
    'REGEXP_EXTRACT',
    'REGEXP_EXTRACT_ALL',
    'REGEXP_LIKE',
    'REGEXP_REPLACE',
    'REPEAT',
    'REPLACE',
    'REVERSE',
    'RIGHT',
    'RINT',
    'ROUND',
    'RPAD',
    'RTRIM',
    'SCHEMA_OF_CSV',
    'SENTENCES',
    'SHA',
    'SHA1',
    'SHA2',
    'SHIFTLEFT',
    'SHIFTRIGHT',
    'SHIFTRIGHTUNSIGNED',
    'SIGN',
    'SIGNUM',
    'SIN',
    'SINH',
    'SMALLINT',
    'SOUNDEX',
    'SPACE',
    'SPARK_PARTITION_ID',
    'SPLIT',
    'SQRT',
    'STACK',
    'SUBSTR',
    'SUBSTRING',
    'SUBSTRING_INDEX',
    'TAN',
    'TANH',
    'TIMESTAMP',
    'TINYINT',
    'TO_CSV',
    'TRANSFORM_KEYS',
    'TRANSFORM_VALUES',
    'TRANSLATE',
    'TRIM',
    'TRY_ADD',
    'TRY_DIVIDE',
    'TYPEOF',
    'UCASE',
    'UNBASE64',
    'UNHEX',
    'UPPER',
    'UUID',
    'VERSION',
    'WIDTH_BUCKET',
    'XPATH',
    'XPATH_BOOLEAN',
    'XPATH_DOUBLE',
    'XPATH_FLOAT',
    'XPATH_INT',
    'XPATH_LONG',
    'XPATH_NUMBER',
    'XPATH_SHORT',
    'XPATH_STRING',
    'XXHASH64',
    'ZIP_WITH',
  ],
  cast: ['CAST'],
  // Shorthand functions to use in place of CASE expression
  caseAbbrev: ['COALESCE', 'NULLIF'],
  // Parameterized data types
  // https://spark.apache.org/docs/latest/sql-ref-datatypes.html
  dataTypes: [
    'DECIMAL',
    'DEC',
    'NUMERIC',
    // No varchar type in Spark, only STRING. Added for the sake of tests
    'VARCHAR',
  ],
});
