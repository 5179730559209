import { flatKeywordList } from '../../utils';

export const keywords = flatKeywordList({
  // https://deepkb.com/CO_000013/en/kb/IMPORT-fbfa59f0-2bf1-31fe-bb7b-0f9efe9932c6/spark-sql-keywords
  all: [
    'ADD',
    'AFTER',
    'ALL',
    'ALTER',
    'ANALYZE',
    'AND',
    'ANTI',
    'ANY',
    'ARCHIVE',
    'ARRAY',
    'AS',
    'ASC',
    'AT',
    'AUTHORIZATION',
    'BETWEEN',
    'BOTH',
    'BUCKET',
    'BUCKETS',
    'BY',
    'CACHE',
    'CASCADE',
    'CAST',
    'CHANGE',
    'CHECK',
    'CLEAR',
    'CLUSTER',
    'CLUSTERED',
    'CODEGEN',
    'COLLATE',
    'COLLECTION',
    'COLUMN',
    'COLUMNS',
    'COMMENT',
    'COMMIT',
    'COMPACT',
    'COMPACTIONS',
    'COMPUTE',
    'CONCATENATE',
    'CONSTRAINT',
    'COST',
    'CREATE',
    'CROSS',
    'CUBE',
    'CURRENT',
    'CURRENT_DATE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'DATA',
    'DATABASE',
    'DATABASES',
    'DAY',
    'DBPROPERTIES',
    'DEFINED',
    'DELETE',
    'DELIMITED',
    'DESC',
    'DESCRIBE',
    'DFS',
    'DIRECTORIES',
    'DIRECTORY',
    'DISTINCT',
    'DISTRIBUTE',
    'DIV',
    'DROP',
    'ESCAPE',
    'ESCAPED',
    'EXCEPT',
    'EXCHANGE',
    'EXISTS',
    'EXPORT',
    'EXTENDED',
    'EXTERNAL',
    'EXTRACT',
    'FALSE',
    'FETCH',
    'FIELDS',
    'FILTER',
    'FILEFORMAT',
    'FIRST',
    'FIRST_VALUE',
    'FOLLOWING',
    'FOR',
    'FOREIGN',
    'FORMAT',
    'FORMATTED',
    'FULL',
    'FUNCTION',
    'FUNCTIONS',
    'GLOBAL',
    'GRANT',
    'GROUP',
    'GROUPING',
    'HOUR',
    'IF',
    'IGNORE',
    'IMPORT',
    'IN',
    'INDEX',
    'INDEXES',
    'INNER',
    'INPATH',
    'INPUTFORMAT',
    'INTERSECT',
    'INTERVAL',
    'INTO',
    'IS',
    'ITEMS',
    'KEYS',
    'LAST',
    'LAST_VALUE',
    'LATERAL',
    'LAZY',
    'LEADING',
    'LEFT',
    'LIKE',
    'LINES',
    'LIST',
    'LOCAL',
    'LOCATION',
    'LOCK',
    'LOCKS',
    'LOGICAL',
    'MACRO',
    'MAP',
    'MATCHED',
    'MERGE',
    'MINUTE',
    'MONTH',
    'MSCK',
    'NAMESPACE',
    'NAMESPACES',
    'NATURAL',
    'NO',
    'NOT',
    'NULL',
    'NULLS',
    'OF',
    'ONLY',
    'OPTION',
    'OPTIONS',
    'OR',
    'ORDER',
    'OUT',
    'OUTER',
    'OUTPUTFORMAT',
    'OVER',
    'OVERLAPS',
    'OVERLAY',
    'OVERWRITE',
    'OWNER',
    'PARTITION',
    'PARTITIONED',
    'PARTITIONS',
    'PERCENT',
    'PLACING',
    'POSITION',
    'PRECEDING',
    'PRIMARY',
    'PRINCIPALS',
    'PROPERTIES',
    'PURGE',
    'QUERY',
    'RANGE',
    'RECORDREADER',
    'RECORDWRITER',
    'RECOVER',
    'REDUCE',
    'REFERENCES',
    'RENAME',
    'REPAIR',
    'REPLACE',
    'RESPECT',
    'RESTRICT',
    'REVOKE',
    'RIGHT',
    'RLIKE',
    'ROLE',
    'ROLES',
    'ROLLBACK',
    'ROLLUP',
    'ROW',
    'ROWS',
    'SCHEMA',
    'SECOND',
    'SELECT',
    'SEMI',
    'SEPARATED',
    'SERDE',
    'SERDEPROPERTIES',
    'SESSION_USER',
    'SETS',
    'SHOW',
    'SKEWED',
    'SOME',
    'SORT',
    'SORTED',
    'START',
    'STATISTICS',
    'STORED',
    'STRATIFY',
    'STRUCT',
    'SUBSTR',
    'SUBSTRING',
    'TABLE',
    'TABLES',
    'TBLPROPERTIES',
    'TEMPORARY',
    'TERMINATED',
    'THEN',
    'TO',
    'TOUCH',
    'TRAILING',
    'TRANSACTION',
    'TRANSACTIONS',
    'TRIM',
    'TRUE',
    'TRUNCATE',
    'UNARCHIVE',
    'UNBOUNDED',
    'UNCACHE',
    'UNIQUE',
    'UNKNOWN',
    'UNLOCK',
    'UNSET',
    'USE',
    'USER',
    'USING',
    'VIEW',
    'WINDOW',
    'YEAR',
    // other
    'ANALYSE',
    'ARRAY_ZIP',
    'COALESCE',
    'CONTAINS',
    'CONVERT',
    'DAYS',
    'DAY_HOUR',
    'DAY_MINUTE',
    'DAY_SECOND',
    'DECODE',
    'DEFAULT',
    'DISTINCTROW',
    'ENCODE',
    'EXPLODE',
    'EXPLODE_OUTER',
    'FIXED',
    'GREATEST',
    'GROUP_CONCAT',
    'HOURS',
    'HOUR_MINUTE',
    'HOUR_SECOND',
    'IFNULL',
    'LEAST',
    'LEVEL',
    'MINUTE_SECOND',
    'NULLIF',
    'OFFSET',
    'ON',
    'OPTIMIZE',
    'REGEXP',
    'SEPARATOR',
    'SIZE',
    'STRING',
    'TYPE',
    'TYPES',
    'UNSIGNED',
    'VARIABLES',
    'YEAR_MONTH',
  ],
});
