import { flatKeywordList } from '../../utils';

export const keywords = flatKeywordList({
  // https://docs.microsoft.com/en-us/sql/t-sql/language-elements/reserved-keywords-transact-sql?view=sql-server-ver15
  standard: [
    'ADD',
    'ALL',
    'ALTER',
    'AND',
    'ANY',
    'AS',
    'ASC',
    'AUTHORIZATION',
    'BACKUP',
    'BEGIN',
    'BETWEEN',
    'BREAK',
    'BROWSE',
    'BULK',
    'BY',
    'CASCADE',
    'CHECK',
    'CHECKPOINT',
    'CLOSE',
    'CLUSTERED',
    'COALESCE',
    'COLLATE',
    'COLUMN',
    'COMMIT',
    'COMPUTE',
    'CONSTRAINT',
    'CONTAINS',
    'CONTAINSTABLE',
    'CONTINUE',
    'CONVERT',
    'CREATE',
    'CROSS',
    'CURRENT',
    'CURRENT_DATE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'CURSOR',
    'DATABASE',
    'DBCC',
    'DEALLOCATE',
    'DECLARE',
    'DEFAULT',
    'DELETE',
    'DENY',
    'DESC',
    'DISK',
    'DISTINCT',
    'DISTRIBUTED',
    'DOUBLE',
    'DROP',
    'DUMP',
    'ERRLVL',
    'ESCAPE',
    'EXEC',
    'EXECUTE',
    'EXISTS',
    'EXIT',
    'EXTERNAL',
    'FETCH',
    'FILE',
    'FILLFACTOR',
    'FOR',
    'FOREIGN',
    'FREETEXT',
    'FREETEXTTABLE',
    'FROM',
    'FULL',
    'FUNCTION',
    'GOTO',
    'GRANT',
    'GROUP',
    'HAVING',
    'HOLDLOCK',
    'IDENTITY',
    'IDENTITYCOL',
    'IDENTITY_INSERT',
    'IF',
    'IN',
    'INDEX',
    'INNER',
    'INSERT',
    'INTERSECT',
    'INTO',
    'IS',
    'JOIN',
    'KEY',
    'KILL',
    'LEFT',
    'LIKE',
    'LINENO',
    'LOAD',
    'MERGE',
    'NATIONAL',
    'NOCHECK',
    'NONCLUSTERED',
    'NOT',
    'NULL',
    'NULLIF',
    'OF',
    'OFF',
    'OFFSETS',
    'ON',
    'OPEN',
    'OPENDATASOURCE',
    'OPENQUERY',
    'OPENROWSET',
    'OPENXML',
    'OPTION',
    'OR',
    'ORDER',
    'OUTER',
    'OVER',
    'PERCENT',
    'PIVOT',
    'PLAN',
    'PRECISION',
    'PRIMARY',
    'PRINT',
    'PROC',
    'PROCEDURE',
    'PUBLIC',
    'RAISERROR',
    'READ',
    'READTEXT',
    'RECONFIGURE',
    'REFERENCES',
    'REPLICATION',
    'RESTORE',
    'RESTRICT',
    'RETURN',
    'REVERT',
    'REVOKE',
    'RIGHT',
    'ROLLBACK',
    'ROWCOUNT',
    'ROWGUIDCOL',
    'RULE',
    'SAVE',
    'SCHEMA',
    'SECURITYAUDIT',
    'SELECT',
    'SEMANTICKEYPHRASETABLE',
    'SEMANTICSIMILARITYDETAILSTABLE',
    'SEMANTICSIMILARITYTABLE',
    'SESSION_USER',
    'SET',
    'SETUSER',
    'SHUTDOWN',
    'SOME',
    'STATISTICS',
    'SYSTEM_USER',
    'TABLE',
    'TABLESAMPLE',
    'TEXTSIZE',
    'THEN',
    'TO',
    'TOP',
    'TRAN',
    'TRANSACTION',
    'TRIGGER',
    'TRUNCATE',
    'TRY_CONVERT',
    'TSEQUAL',
    'UNION',
    'UNIQUE',
    'UNPIVOT',
    'UPDATE',
    'UPDATETEXT',
    'USE',
    'USER',
    'VALUES',
    'VARYING',
    'VIEW',
    'WAITFOR',
    'WHERE',
    'WHILE',
    'WITH',
    'WITHIN GROUP',
    'WRITETEXT',
  ],
  odbc: [
    'ABSOLUTE',
    'ACTION',
    'ADA',
    'ADD',
    'ALL',
    'ALLOCATE',
    'ALTER',
    'AND',
    'ANY',
    'ARE',
    'AS',
    'ASC',
    'ASSERTION',
    'AT',
    'AUTHORIZATION',
    'AVG',
    'BEGIN',
    'BETWEEN',
    'BIT',
    'BIT_LENGTH',
    'BOTH',
    'BY',
    'CASCADE',
    'CASCADED',
    'CAST',
    'CATALOG',
    'CHAR',
    'CHARACTER',
    'CHARACTER_LENGTH',
    'CHAR_LENGTH',
    'CHECK',
    'CLOSE',
    'COALESCE',
    'COLLATE',
    'COLLATION',
    'COLUMN',
    'COMMIT',
    'CONNECT',
    'CONNECTION',
    'CONSTRAINT',
    'CONSTRAINTS',
    'CONTINUE',
    'CONVERT',
    'CORRESPONDING',
    'COUNT',
    'CREATE',
    'CROSS',
    'CURRENT',
    'CURRENT_DATE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'CURSOR',
    'DATE',
    'DAY',
    'DEALLOCATE',
    'DEC',
    'DECIMAL',
    'DECLARE',
    'DEFAULT',
    'DEFERRABLE',
    'DEFERRED',
    'DELETE',
    'DESC',
    'DESCRIBE',
    'DESCRIPTOR',
    'DIAGNOSTICS',
    'DISCONNECT',
    'DISTINCT',
    'DOMAIN',
    'DOUBLE',
    'DROP',
    'END-EXEC',
    'ESCAPE',
    'EXCEPTION',
    'EXEC',
    'EXECUTE',
    'EXISTS',
    'EXTERNAL',
    'EXTRACT',
    'FALSE',
    'FETCH',
    'FIRST',
    'FLOAT',
    'FOR',
    'FOREIGN',
    'FORTRAN',
    'FOUND',
    'FROM',
    'FULL',
    'GET',
    'GLOBAL',
    'GO',
    'GOTO',
    'GRANT',
    'GROUP',
    'HAVING',
    'HOUR',
    'IDENTITY',
    'IMMEDIATE',
    'IN',
    'INCLUDE',
    'INDEX',
    'INDICATOR',
    'INITIALLY',
    'INNER',
    'INPUT',
    'INSENSITIVE',
    'INSERT',
    'INT',
    'INTEGER',
    'INTERSECT',
    'INTERVAL',
    'INTO',
    'IS',
    'ISOLATION',
    'JOIN',
    'KEY',
    'LANGUAGE',
    'LAST',
    'LEADING',
    'LEFT',
    'LEVEL',
    'LIKE',
    'LOCAL',
    'LOWER',
    'MATCH',
    'MAX',
    'MIN',
    'MINUTE',
    'MODULE',
    'MONTH',
    'NAMES',
    'NATIONAL',
    'NATURAL',
    'NCHAR',
    'NEXT',
    'NO',
    'NONE',
    'NOT',
    'NULL',
    'NULLIF',
    'NUMERIC',
    'OCTET_LENGTH',
    'OF',
    'ONLY',
    'OPEN',
    'OPTION',
    'OR',
    'ORDER',
    'OUTER',
    'OUTPUT',
    'OVERLAPS',
    'PAD',
    'PARTIAL',
    'PASCAL',
    'POSITION',
    'PRECISION',
    'PREPARE',
    'PRESERVE',
    'PRIMARY',
    'PRIOR',
    'PRIVILEGES',
    'PROCEDURE',
    'PUBLIC',
    'READ',
    'REAL',
    'REFERENCES',
    'RELATIVE',
    'RESTRICT',
    'REVOKE',
    'RIGHT',
    'ROLLBACK',
    'ROWS',
    'SCHEMA',
    'SCROLL',
    'SECOND',
    'SECTION',
    'SELECT',
    'SESSION',
    'SESSION_USER',
    'SET',
    'SIZE',
    'SMALLINT',
    'SOME',
    'SPACE',
    'SQL',
    'SQLCA',
    'SQLCODE',
    'SQLERROR',
    'SQLSTATE',
    'SQLWARNING',
    'SUBSTRING',
    'SUM',
    'SYSTEM_USER',
    'TABLE',
    'TEMPORARY',
    'TIME',
    'TIMESTAMP',
    'TIMEZONE_HOUR',
    'TIMEZONE_MINUTE',
    'TO',
    'TRAILING',
    'TRANSACTION',
    'TRANSLATE',
    'TRANSLATION',
    'TRIM',
    'TRUE',
    'UNION',
    'UNIQUE',
    'UNKNOWN',
    'UPDATE',
    'UPPER',
    'USAGE',
    'USER',
    'VALUE',
    'VALUES',
    'VARCHAR',
    'VARYING',
    'VIEW',
    'WHENEVER',
    'WHERE',
    'WITH',
    'WORK',
    'WRITE',
    'YEAR',
    'ZONE',
  ],
});
