import { flatKeywordList } from '../../utils';

export const functions = flatKeywordList({
  // https://docs.aws.amazon.com/redshift/latest/dg/c_Aggregate_Functions.html
  aggregate: [
    'ANY_VALUE',
    'APPROXIMATE PERCENTILE_DISC',
    'AVG',
    'COUNT',
    'LISTAGG',
    'MAX',
    'MEDIAN',
    'MIN',
    'PERCENTILE_CONT',
    'STDDEV_SAMP',
    'STDDEV_POP',
    'SUM',
    'VAR_SAMP',
    'VAR_POP',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/c_Array_Functions.html
  array: [
    'array',
    'array_concat',
    'array_flatten',
    'get_array_length',
    'split_to_array',
    'subarray',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/c_bitwise_aggregate_functions.html
  bitwise: ['BIT_AND', 'BIT_OR', 'BOOL_AND', 'BOOL_OR'],
  // https://docs.aws.amazon.com/redshift/latest/dg/c_conditional_expressions.html
  conditional: ['COALESCE', 'DECODE', 'GREATEST', 'LEAST', 'NVL', 'NVL2', 'NULLIF'],
  // https://docs.aws.amazon.com/redshift/latest/dg/Date_functions_header.html
  dateTime: [
    'ADD_MONTHS',
    'AT TIME ZONE',
    'CONVERT_TIMEZONE',
    'CURRENT_DATE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'DATE_CMP',
    'DATE_CMP_TIMESTAMP',
    'DATE_CMP_TIMESTAMPTZ',
    'DATE_PART_YEAR',
    'DATEADD',
    'DATEDIFF',
    'DATE_PART',
    'DATE_TRUNC',
    'EXTRACT',
    'GETDATE',
    'INTERVAL_CMP',
    'LAST_DAY',
    'MONTHS_BETWEEN',
    'NEXT_DAY',
    'SYSDATE',
    'TIMEOFDAY',
    'TIMESTAMP_CMP',
    'TIMESTAMP_CMP_DATE',
    'TIMESTAMP_CMP_TIMESTAMPTZ',
    'TIMESTAMPTZ_CMP',
    'TIMESTAMPTZ_CMP_DATE',
    'TIMESTAMPTZ_CMP_TIMESTAMP',
    'TIMEZONE',
    'TO_TIMESTAMP',
    'TRUNC',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/geospatial-functions.html
  spatial: [
    'AddBBox',
    'DropBBox',
    'GeometryType',
    'ST_AddPoint',
    'ST_Angle',
    'ST_Area',
    'ST_AsBinary',
    'ST_AsEWKB',
    'ST_AsEWKT',
    'ST_AsGeoJSON',
    'ST_AsText',
    'ST_Azimuth',
    'ST_Boundary',
    'ST_Collect',
    'ST_Contains',
    'ST_ContainsProperly',
    'ST_ConvexHull',
    'ST_CoveredBy',
    'ST_Covers',
    'ST_Crosses',
    'ST_Dimension',
    'ST_Disjoint',
    'ST_Distance',
    'ST_DistanceSphere',
    'ST_DWithin',
    'ST_EndPoint',
    'ST_Envelope',
    'ST_Equals',
    'ST_ExteriorRing',
    'ST_Force2D',
    'ST_Force3D',
    'ST_Force3DM',
    'ST_Force3DZ',
    'ST_Force4D',
    'ST_GeometryN',
    'ST_GeometryType',
    'ST_GeomFromEWKB',
    'ST_GeomFromEWKT',
    'ST_GeomFromText',
    'ST_GeomFromWKB',
    'ST_InteriorRingN',
    'ST_Intersects',
    'ST_IsPolygonCCW',
    'ST_IsPolygonCW',
    'ST_IsClosed',
    'ST_IsCollection',
    'ST_IsEmpty',
    'ST_IsSimple',
    'ST_IsValid',
    'ST_Length',
    'ST_LengthSphere',
    'ST_Length2D',
    'ST_LineFromMultiPoint',
    'ST_LineInterpolatePoint',
    'ST_M',
    'ST_MakeEnvelope',
    'ST_MakeLine',
    'ST_MakePoint',
    'ST_MakePolygon',
    'ST_MemSize',
    'ST_MMax',
    'ST_MMin',
    'ST_Multi',
    'ST_NDims',
    'ST_NPoints',
    'ST_NRings',
    'ST_NumGeometries',
    'ST_NumInteriorRings',
    'ST_NumPoints',
    'ST_Perimeter',
    'ST_Perimeter2D',
    'ST_Point',
    'ST_PointN',
    'ST_Points',
    'ST_Polygon',
    'ST_RemovePoint',
    'ST_Reverse',
    'ST_SetPoint',
    'ST_SetSRID',
    'ST_Simplify',
    'ST_SRID',
    'ST_StartPoint',
    'ST_Touches',
    'ST_Within',
    'ST_X',
    'ST_XMax',
    'ST_XMin',
    'ST_Y',
    'ST_YMax',
    'ST_YMin',
    'ST_Z',
    'ST_ZMax',
    'ST_ZMin',
    'SupportsBBox',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/hash-functions.html
  hash: ['CHECKSUM', 'FUNC_SHA1', 'FNV_HASH', 'MD5', 'SHA', 'SHA1', 'SHA2'],
  // https://docs.aws.amazon.com/redshift/latest/dg/hyperloglog-functions.html
  hyperLogLog: ['HLL', 'HLL_CREATE_SKETCH', 'HLL_CARDINALITY', 'HLL_COMBINE'],
  // https://docs.aws.amazon.com/redshift/latest/dg/json-functions.html
  json: [
    'IS_VALID_JSON',
    'IS_VALID_JSON_ARRAY',
    'JSON_ARRAY_LENGTH',
    'JSON_EXTRACT_ARRAY_ELEMENT_TEXT',
    'JSON_EXTRACT_PATH_TEXT',
    'JSON_PARSE',
    'JSON_SERIALIZE',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/Math_functions.html
  math: [
    'ABS',
    'ACOS',
    'ASIN',
    'ATAN',
    'ATAN2',
    'CBRT',
    'CEILING',
    'CEIL',
    'COS',
    'COT',
    'DEGREES',
    'DEXP',
    'DLOG1',
    'DLOG10',
    'EXP',
    'FLOOR',
    'LN',
    'LOG',
    'MOD',
    'PI',
    'POWER',
    'RADIANS',
    'RANDOM',
    'ROUND',
    'SIN',
    'SIGN',
    'SQRT',
    'TAN',
    'TO_HEX',
    'TRUNC',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/ml-function.html
  machineLearning: ['EXPLAIN_MODEL'],
  // https://docs.aws.amazon.com/redshift/latest/dg/String_functions_header.html
  string: [
    'ASCII',
    'BPCHARCMP',
    'BTRIM',
    'BTTEXT_PATTERN_CMP',
    'CHAR_LENGTH',
    'CHARACTER_LENGTH',
    'CHARINDEX',
    'CHR',
    'COLLATE',
    'CONCAT',
    'CRC32',
    'DIFFERENCE',
    'INITCAP',
    'LEFT',
    'RIGHT',
    'LEN',
    'LENGTH',
    'LOWER',
    'LPAD',
    'RPAD',
    'LTRIM',
    'OCTETINDEX',
    'OCTET_LENGTH',
    'POSITION',
    'QUOTE_IDENT',
    'QUOTE_LITERAL',
    'REGEXP_COUNT',
    'REGEXP_INSTR',
    'REGEXP_REPLACE',
    'REGEXP_SUBSTR',
    'REPEAT',
    'REPLACE',
    'REPLICATE',
    'REVERSE',
    'RTRIM',
    'SOUNDEX',
    'SPLIT_PART',
    'STRPOS',
    'STRTOL',
    'SUBSTRING',
    'TEXTLEN',
    'TRANSLATE',
    'TRIM',
    'UPPER',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/c_Type_Info_Functions.html
  superType: [
    'decimal_precision',
    'decimal_scale',
    'is_array',
    'is_bigint',
    'is_boolean',
    'is_char',
    'is_decimal',
    'is_float',
    'is_integer',
    'is_object',
    'is_scalar',
    'is_smallint',
    'is_varchar',
    'json_typeof',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/c_Window_functions.html
  window: [
    'AVG',
    'COUNT',
    'CUME_DIST',
    'DENSE_RANK',
    'FIRST_VALUE',
    'LAST_VALUE',
    'LAG',
    'LEAD',
    'LISTAGG',
    'MAX',
    'MEDIAN',
    'MIN',
    'NTH_VALUE',
    'NTILE',
    'PERCENT_RANK',
    'PERCENTILE_CONT',
    'PERCENTILE_DISC',
    'RANK',
    'RATIO_TO_REPORT',
    'ROW_NUMBER',
    'STDDEV_SAMP',
    'STDDEV_POP',
    'SUM',
    'VAR_SAMP',
    'VAR_POP',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/r_Data_type_formatting.html
  dataType: [
    'CAST',
    'CONVERT',
    'TO_CHAR',
    'TO_DATE',
    'TO_NUMBER',
    'TEXT_TO_INT_ALT',
    'TEXT_TO_NUMERIC_ALT',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/r_System_administration_functions.html
  sysAdmin: [
    'CHANGE_QUERY_PRIORITY',
    'CHANGE_SESSION_PRIORITY',
    'CHANGE_USER_PRIORITY',
    'CURRENT_SETTING',
    'PG_CANCEL_BACKEND',
    'PG_TERMINATE_BACKEND',
    'REBOOT_CLUSTER',
    'SET_CONFIG',
  ],
  // https://docs.aws.amazon.com/redshift/latest/dg/r_System_information_functions.html
  sysInfo: [
    'CURRENT_AWS_ACCOUNT',
    'CURRENT_DATABASE',
    'CURRENT_NAMESPACE',
    'CURRENT_SCHEMA',
    'CURRENT_SCHEMAS',
    'CURRENT_USER',
    'CURRENT_USER_ID',
    'HAS_ASSUMEROLE_PRIVILEGE',
    'HAS_DATABASE_PRIVILEGE',
    'HAS_SCHEMA_PRIVILEGE',
    'HAS_TABLE_PRIVILEGE',
    'PG_BACKEND_PID',
    'PG_GET_COLS',
    'PG_GET_GRANTEE_BY_IAM_ROLE',
    'PG_GET_IAM_ROLE_BY_USER',
    'PG_GET_LATE_BINDING_VIEW_COLS',
    'PG_LAST_COPY_COUNT',
    'PG_LAST_COPY_ID',
    'PG_LAST_UNLOAD_ID',
    'PG_LAST_QUERY_ID',
    'PG_LAST_UNLOAD_COUNT',
    'SESSION_USER',
    'SLICE_NUM',
    'USER',
    'VERSION',
  ],
  dataTypes: [
    'DECIMAL',
    'NUMERIC',
    'CHAR',
    'CHARACTER',
    'VARCHAR',
    'CHARACTER VARYING',
    'NCHAR',
    'NVARCHAR',
    'VARBYTE',
  ],
});
